import styled from 'styled-components';

export const HeroContainer = styled.div`
  margin-top: 100px;
  padding: 96px 60px;
  font-family: Roboto;
  position: relative;
  min-height: 385px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 992px) {
    padding: 64px 40px;
  }
  @media (max-width: 480px) {
    padding: 48px 24px;
    min-height: 285px;
  }
  .hero-image {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`

export const HeroBreadcrumbs = styled.div`
  position: absolute;
  display: flex;
  padding: 24px 60px;
  z-index: 100;
  width: 100%;
  left: 50%;
  top: 0;
  color: white;
  max-width: 1300px;
  transform: translateX(-50%);
  & > * {
    margin: 0 10px 0 0;
  }
`
export const HomeCrumb = styled.span`
  &:hover {
    color: #DDDDDD !important;
  }
`

export const TextContentContainerOneCTA = styled.div`
  padding: 0 15px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100;
  padding: 24px 15px;
  top: 50%;
  left: 50%;
  z-index: 9999;
  width: 100%;
  max-width: 1200px;
  transform: translate(-50%, -50%);
  text-align: center;
  form {
    margin-top: 16px;
    input {
      width: 100%;
      max-width: 506px;
      height: 44px;
      background: #FFFFFF;
      border: 1px solid #D0D5DD;
      box-sizing: border-box;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 30px;
      padding: 10px 14px;
    }
  }
`

export const TextContentContainerTwoCTA = styled.div`
  text-align: center;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100;
  top: 50%;
  left: 50%;
  z-index: 9999;
  width: 100%;
  max-width: 1200px;
  transform: translate(-50%, -50%);
  form {
    margin-top: 32px;
    input {
      width: 506px;
      height: 44px;
      background: #FFFFFF;
      border: 1px solid #D0D5DD;
      box-sizing: border-box;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 30px;
      padding: 10px 14px;
      @media (max-width: 768px) {
        max-width: 506px;
        width: 100%;
      }
    }
  }
`

export const Gradient = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  transform: translateY(-50%);
  background: linear-gradient(180deg, #090069 23.61%, rgba(9, 0, 105, 0.2) 98.49%);
  ${({ type }) =>
  type.includes('blue') &&
  `
    background: #0000C0;
    opacity: 0.6;  
  `}
`

export const CTAContainer = styled.div`
  @media (max-width: 480px) {
    flex-direction: column;
    .button-wrapper {
      margin-left: 0px !important;
      margin-right: 0px !important;
      margin-bottom: 16px !important;
    }
  }
`