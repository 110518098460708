import styled from "styled-components"

export const KnowledgeAccordionWrapper = styled.div`
  padding: 96px 15px;
  font-family: Roboto;
  max-width: 970px;
  margin: 0 auto;
  @media (max-width: 992px) {
    padding: 64px 15px;
    max-width: 770px;
  }
  @media (max-width: 768px) {
    padding: 40px 0;
    max-width: 506px;
  }
  @media (max-width: 576px) {
    padding: 40px 15px;
  }
`
export const KnowledgeAccordionHeading = styled.div`
  h4 {
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: #1B1B1B;
    padding-bottom: 28px;
    @media (max-width: 768px) {
      padding-bottom: 12px;
    }
  }
  p {
    margin: 20px 0 0;
  }
`

export const KnowledgeAccordionContent = styled.div`
  .knowledge-accordion--content {
    margin: 32px 0 0;
  }
`

export const KnowledgeAccordionItem = styled.div`
  white-space: normal;
  margin: 20px 0px;
  max-width: 100%;
  .knowledge-accordion--item-wrapper {
    border-radius: 40px;
    border: 1px solid #D0D5DD;
    transition: all 0.2s ease-in-out;
    .knowledge-accordion--item-heading {
      font-size: 16px;
      font-weight: 700;
      padding: 15px 45px 15px 20px;
      cursor: pointer;
      position: relative;
      color: #1B1B1B;
      &:after {
        content: ' ';
        position: absolute;
        width: 24px;
        height: 24px;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%0A%3E%3Cpath d='M6.34317 7.75732L4.92896 9.17154L12 16.2426L19.0711 9.17157L17.6569 7.75735L12 13.4142L6.34317 7.75732Z' fill='currentColor' /%3E%3C/svg%3E");
        right: 15px;
        top: 15px;
        transition: all 0.2s ease-in-out;
      }
    }
    .knowledge-accordion--item-content {
      white-space: normal;
      padding: 0 20px;
      overflow: hidden;
      max-height: 0;
      transition: all 0.2s ease-in-out;
      a {
        display: block;
        text-decoration: none;
        font-size: 16px;
        margin: 16px 0;
        line-height: 150%;
        color: #002DC2;
      }
    }
    &.knowledge-accordion--item-opened {
      background: #F9FAFB;
      border-radius: 20px;
      .knowledge-accordion--item-heading {
        &:after {
          transform: rotate(180deg);
        }
      }
      .knowledge-accordion--item-content {
        max-height: 800px;
        transition: all 0.2s ease-in-out;
      }
    }
  }
`
