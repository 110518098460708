import React from "react"
import { Layout } from "@components/Layout"
import Seo from "../../../components/Seo/Seo"
import ConversionPanel from "@components/ConversionPanel/ConversionPanel"
import { graphql } from "gatsby"
import HeroKnowledgeCenterListing from "@components/Hero/HeroKnowledgeCenterListing.jsx"
import Carousel from "@components/Carousel/Carousel"
import { KnowledgeCenterAccordion } from "@components/Accordions/KnowledgeCenterAccordion"

export default function KnowledgeCenterListing({ data }) {
  const { contentfulPage, allContentfulKnowledgeCenterPost } = data
  const seo = contentfulPage?.pageSeo
  const hero = contentfulPage?.components[0]
  const carousel = contentfulPage?.components[1]
  const conversionPanel = contentfulPage?.components[2]
  const posts = allContentfulKnowledgeCenterPost?.edges

  return (
    <Layout>
      <Seo
        title={seo?.seoTitle}
        description={seo?.seoDescription}
        noIndex={seo?.noIndex}
        canonical={seo?.canonical}
        metaTags={seo?.metaTags}
      />
      <HeroKnowledgeCenterListing component={hero} />
      <KnowledgeCenterAccordion component={posts} />
      <Carousel component={carousel} backgroundColor={"#F2F4F7"} />
      <ConversionPanel component={conversionPanel} />
    </Layout>
  )
}

export const knowledgeCenterListingQuery = graphql`
  query knowledgeCenterListingQuery {
    contentfulPage(internalName: { eq: "Knowledge Center" }) {
      id
      pageSeo {
        graphImage {
          description
          title
          file {
            url
          }
        }
        seoTitle
        seoDescription
        canonical
        noIndex
        metaTags
      }
      components {
        ... on ContentfulComponentHero {
          id
          type
          background
          alignImage
          subheading {
            raw
          }
          kicker
          heading
          headingSize
          callsToAction {
            contentful_id
            id
            internalLink
            label
            type
            link
            variant
          }
          featuredImage {
            gatsbyImageData
            file {
              url
              contentType
            }
          }
        }
        ... on ContentfulComponentConversionPanel {
          id
          textAlign
          alignContent
          background
          kicker
          callsToAction {
            contentful_id
            id
            internalLink
            label
            type
            link
            variant
          }
          headingSize
          heading
          noTopPadding
          noBottomPadding
          subheading {
            raw
          }
          type
        }
        ... on ContentfulComponentCarousel {
          __typename
          id
          type
          subheading {
            subheading
          }
          subheadingTest {
            raw
          }
          heading
          headingSize
          autoplay
          noTopPadding
          noBottomPadding
          carouselItems {
            ... on ContentfulComponentGeneric {
              id
              align
              background
              callsToAction {
                variant
                type
                link
                label
                id
                internalName
                internalLink
                contentful_id
              }
              heading
              headingSize
              subheading {
                raw
              }
              icon {
                gatsbyImageData
                file {
                  url
                }
                title
                description
                contentful_id
              }
              featuredImage {
                gatsbyImageData
                file {
                  url
                }
                title
                description
                contentful_id
              }
            }
          }
        }
      }
    }
    allContentfulKnowledgeCenterPost(
      filter: {id: {ne: "70ae2990-d224-583c-9d7b-b280584d7d3b"}}
    ) {
      edges {
        node {
          id
          title
          slug
          category {
            title
          }
        }
      }
    }
  }
`
