import React, { useState } from 'react';
import { Heading } from '@atoms';
import { Link } from "gatsby"
import { KnowledgeAccordionWrapper, KnowledgeAccordionHeading, KnowledgeAccordionContent, KnowledgeAccordionItem } from './styles/KnowledgeCenterAccordion.styled';

const QuestionSection = ({ topic, questions }) => {
  questions.sort((a, b) => a.title.localeCompare(b.title))

  return (
    <div className="knowledge-accordion--item-content">
      {questions && questions.length > 0 &&
        questions.filter(item => item?.category[0]?.title.includes(topic)).map((question) => {
          return (
            <Link to={`/article/${question?.slug}`}>
              {question?.title}
            </Link>
          )
        })
      }
    </div>
  )
}

export const KnowledgeCenterAccordion = ({ component }) => {

  const [knowledgeOpened, setKnowledgeOpened] = useState({});

  const handleKnowledgeClicked = (index) => {
    setKnowledgeOpened({
      ...knowledgeOpened,
      [index]: !knowledgeOpened[index],
    });
  }

  const categories = []
  const questions = []

  component.map((item) => {
    if (item?.node?.category[0]?.title && !categories.includes(item?.node?.category[0]?.title)) {
      categories.push(item?.node?.category[0]?.title)
    }
    if (item?.node?.title && !questions.includes(item?.node?.title)) {
      questions.push(item?.node)
    }
  })

  categories.sort()

  return (
    <KnowledgeAccordionWrapper
      data-comp={KnowledgeCenterAccordion?.displayName}
    >
      <KnowledgeAccordionHeading>
        <Heading headingSize='H4'>Browse by solution</Heading>
      </KnowledgeAccordionHeading>
      {categories?.length > 0 && (
        <KnowledgeAccordionContent>
          {categories.map((topic, index) => (
            <>
              <KnowledgeAccordionItem key={index}>
                <div className={`knowledge-accordion--item-wrapper ${knowledgeOpened[index] ? 'knowledge-accordion--item-opened' : ''}`}>
                  <div className="knowledge-accordion--item-heading" onClick={() => handleKnowledgeClicked(index)}>
                    {topic}
                  </div>
                  <QuestionSection topic={topic} questions={questions} />
                </div>
              </KnowledgeAccordionItem>
            </>
          ))}
        </KnowledgeAccordionContent>
      )}
    </KnowledgeAccordionWrapper>
  );
};

KnowledgeCenterAccordion.displayName = 'KnowledgeAccordion';
